export function checkIdentification(controller, identificationAttributes) {
    return controller.stimulate('Form::CuveeReflex#valid_cuvee?',
                                identificationAttributes)
                     .then(promise => {
                               let valid = promise.payload.valid

                               if (!valid) {
                                   toastr.error(promise.payload.errors)
                               }

                               return promise.payload.valid
                     })
}

export function serializeIdentificationAttributes(form, domain_id, cuvee_id = null) {
    let identificationAttributes = lodash.omitBy({ domain_id: domain_id,
                                                   cuvee_id: cuvee_id },
                                                 lodash.isNil)

    let pattern = /appellation_id|name|color_type_id/

    $(form).serializeArray()
           .forEach( (field) => {
        if (pattern.test(field.name)) {
            let key = field.name.match(pattern)[0]
            identificationAttributes[key] = field.value;
        }
    } )

    return identificationAttributes
}