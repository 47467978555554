import ApplicationController from '@controllers/application_controller.js';
import { checkIdentification, serializeIdentificationAttributes } from './helpers/cuvees_helper';

export default class extends ApplicationController {
    static values = {
        forDomain: { type: Boolean, default: false }
    }
    initialize() {
        const action    = $(this.element).attr('action')
        const cuvee_id  = new URL(action, window.location).pathname
                                                          .split('/')
                                                          .pop()
        this.alreadySubmited = false

        $(this.element).on('submit', (e) =>{
            const domain_id = this.getDomainID(action)

            if (!this.alreadySubmited) {
                e.preventDefault()

                let identificationAttributes = this.forDomainValue ? { cuvee_id: cuvee_id,
                                                                       domain_id: domain_id }
                                                                   : serializeIdentificationAttributes(this.element,
                                                                                                       domain_id,
                                                                                                       cuvee_id)

                checkIdentification(this, identificationAttributes).then(valid => {
                    this.alreadySubmited = true
                    if (valid) {
                        this.stimulate('Form::CuveeReflex#check_aliases',
                                    identificationAttributes)
                            .then((response) => {
                                if (!response.payload.changes) {
                                    $('.modal-header').find('.btn-close')
                                                    .trigger('click')
                                    return;
                                }

                                if (response.payload.aliases) {
                                    $(this.element).find('fieldset')
                                                   .attr('inert', true)
                                    this.setModalFooter()
                                }
                                // else {
                                //     Turbo.navigator.submitForm(this.element)
                                // }
                            })
                    }
                })
            }
        });
    }

    getDomainID(action) {
        if (!this.forDomainValue) { return new URLSearchParams(action.split('?')[1]).get('domain_id'); }

        return $(this.element).find('[name="cuvee[domain_id][]"]')
                              .val()[0]
    }

    setModalFooter() {
        $('.modal-footer .btn-rouge').removeClass('d-none')
    }
}
