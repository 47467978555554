import ApplicationController from '@controllers/application_controller.js'

export default class extends ApplicationController {
    confirmLink(event) {
        let liDOM = $(event.currentTarget)
        let cuveeCard = $(liDOM.html())
        cuveeCard.find('.pointer')
                 .removeClass('pointer')

        this.toggleSearch(cuveeCard);

        const cuvee_id = cuveeCard.attr('id')
                                  .match(/^cuvee_(\d+)$/)[1]
        this.enableSubmitButton(cuvee_id);
    }

    cancelChoice(event) {
        let autocompleteCard = $('.autocomplete-card')
        this.disableSubmitButton();

        // Remove previous clicked card
        autocompleteCard.find('div[id^=cuvee_]')
                        .remove()

        // Remove cancel button
        autocompleteCard.find('.card-body .cancel-button')
                        .addClass('d-none')

        // Reenable search
        autocompleteCard.find('.card-body .autocomplete-body')
                        .removeClass('d-none')
    }

    toggleSearch(cuveeCard) {
        $('#search').val('')
        $('ul.autocomplete-group').empty()
        $('.card-body .autocomplete-body').addClass('d-none')
        $('.card-body .cancel-button').removeClass('d-none')
                                      .after(cuveeCard)
    }



    enableSubmitButton(cuvee_id) {
        let submitButton = $('#submit-alias');
        submitButton.removeClass('d-none');

        $('<input>').attr({
            type: 'hidden',
            value: cuvee_id,
            name: 'alias[wia_id]'
        }).appendTo(submitButton.get(0).form)
    }

    disableSubmitButton() {
        let submitButton = $('#submit-alias');
        submitButton.addClass('d-none');

        $(submitButton.get(0).form).find('[name="alias[wia_id]"]')
                                   .remove();
    }
}