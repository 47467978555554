import ApplicationController from '@controllers/application_controller.js';
import { checkIdentification, serializeIdentificationAttributes } from './helpers/cuvees_helper';

export default class extends ApplicationController {
    static outlets = ['form--assemblages']

    initialize() {
        let _this = this;
        const domain_id = new URLSearchParams(window.location.search).get('domain_id');

        $(this.element).on('submit', (e) =>{
            e.preventDefault();

            let identificationAttributes = serializeIdentificationAttributes(this.element,
                                                                             domain_id)

            //First look if cuvee is valid
            checkIdentification(this, identificationAttributes).then( valid => {
                if (valid && this.check_assemblage()) {
                    // Cuvee is valid so continue
                    let domainModalClosed = $('.modal#cuvee-domain-rules').length == 0
                    let aliasModalClosed  = $('.modal#modal-aliases').length == 0

                    let condition = $("#cuvee-rules").is(':empty')
                                    && domainModalClosed
                                    && !$('#domainRules').hasClass('opacity-50')
                                    && aliasModalClosed

                    // Open domain rules modal if cuvee rules is empty
                    if (condition) {
                        toastr.warning("Voulez-vous ajouter des règles issues du domaine à cette cuvée ?")
                        $('#domainRules').trigger('click')

                        // Remove close modal button
                        setTimeout(() =>{
                            // Scroll To bottom
                            window.scrollTo({ left: 0,
                                                top: document.body.scrollHeight,
                                                behavior: "smooth" });
                            this.setModalFooter()
                        }, 250)
                    } else if (aliasModalClosed) {
                        this.stimulate('Form::CuveeReflex#check_aliases',
                                        identificationAttributes)
                            .then((response) => {
                                if (response.payload.aliases) {
                                    this.setModalFooter()
                                } else {
                                    Turbo.navigator.submitForm(this.element);
                                }

                        });
                    } else {
                        Turbo.navigator.submitForm(this.element);
                    }
                }

            })
        })

        $(this.element).on('turbo:submit-end', (e) => {
            if (!e.detail.success) {
                window.scrollTo({ left: 0,
                                  top: 0,
                                  behavior: "smooth" });
            }

        })
    }

    setModalFooter() {
        $('.modal-header .btn-close').remove()
        $('.modal-footer .btn-rouge').removeClass('d-none')

        let submitButton = $(this.element).find(':submit')
                                          .first()
                                          .clone();

        submitButton.attr('data-bs-dismiss', 'modal')
        submitButton.attr('data-action', 'click->edit-modal#dismiss')
        $('.modal-footer .btn-primary').replaceWith(submitButton);
    }

    serializeIdentificationAttributes() {
        let identificationAttributes = { domain_id: new URL(window.location).searchParams.get('domain_id') }

        let pattern = /appellation_id|name|color_type_id/
        $(this.element).serializeArray()
                       .forEach( (field) => {
            if (pattern.test(field.name)) {
                let key = field.name.match(pattern)[0]
                identificationAttributes[key] = field.value;
            }
        } )

        return identificationAttributes;
    }

    deleteRule(event) {
        $(event.currentTarget).closest('.row[certification-name]')
                              .remove()
    }

    check_assemblage() {
        let assemblageController = this.formAssemblagesOutlet
        if (assemblageController.hasEmptySelect(this.element)) {
            toastr.error("Vous ne pouvez pas enregistrer avec un cépage vide");
            return false;
        }

        let values = assemblageController.getSelectValues()
        if ( values.some( (elem) => (elem > 100) || (elem < 0) )) {
            toastr.error("Vous avez des pourcentages incohérents (> 100% ou < 0%)")
            return false;
        }

        let sum = values.reduce((x, y) => ~~x + ~~y, 0)
        if ((sum > 100) || ((sum === 100) && (values.some( v => !v)))) {
            toastr.error("La somme de vos pourcentages est supérieur à 100% ou inférieur à 0%")
            return false;
        } else {
            return true;
        }
    }
}